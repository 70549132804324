// 负责封装 axios
import axios from 'axios'
import { ElMessage  } from 'element-plus'
// 创建一个 axios 实例
const request = axios.create({
  // baseURL：项目的基准地址
  // vue-cli --> webpack --> Node
  // process --> 是 Node 中的全局变量
  // process.env --> 获取当前用户的环境变量信息
  // VUE_APP_BASE_API 则是我们配置的项目的根域名
  // 开发环境 --> 就是目前开发中代码的环境 --> development
  // 生产环境 --> 就是项目上线以后运行的环境 --> production
  // 当运行 npm run dev 时候，会解析 .env.development 这个文件
  // 当运行 npm run build 时候，会解析 .env.production 这个文件
  // 取出其中定义变量，集成到项目中使用即可
  // ---------------------------------
  // 获取变量的过程，在使用 npm run xxx 命令时，node 就已经获取到变量传入到 env 中了
  // baseURL: 'http://192.168.100.41:8200',
  // baseURL: 'http://192.168.100.21:8200/',
  // baseURL: 'https://3579.com.cn/Wst',
  baseURL: 'https://api.lphadmin.cn/',
  timeout: 5000
})

// 请求拦截器
// request.interceptors.request.use(
//   config => { 
//    // 在拦截器之前做什么
//   },
//   error => {
//     // 失败的回调
//     return Promise.reject(error)
//   }
// )
// 响应拦截器
request.interceptors.response.use(
  response => {
    if (response.data.code === 200) {
      // response.data 就是返回后端真正响应的数据
      return response.data
    } else {
      // 接口响应失败错误提示
      ElMessage({
        message: response.data.msg,
        type: 'error'
      })
      return Promise.reject(new Error(response.data.msg))
    }
  },
  async(error) => {
    // 对网络延迟进行错误提示
    ElMessage({
      message: error.message,
      type: 'error'
    })
    return Promise.reject(error)
  }
)
// 暴露 axios 实例
export default request

<template>
  <Meting />
  <div class="footer">
    <span> Copyright </span>
    <span> 小晖晖 </span>
    <span> 赣ICP备2021011775号 </span>
  </div>
</template>

<script setup>
import Meting from "../components/Right/Meting.vue";
components: {
  Meting;
}
</script>

<script>
export default {
  name: "",
};
</script>

<style lang="scss">
.footer {
  text-align: center;
  // line-height: 52px;
  width: 100%;
  padding: 30px 0 100px;
  // background-color: #333;
  // height: 52px;
  color: #a5a4a4;
  font-size: 16px;
}
</style>

<template> 
    <div class="Meting">
      <meting-js
       server="netease"
       type="playlist"
       id="8021677662"
       autoplay="true"
       loop="all"
       order="list"
       preload="auto"
       volume="0.5"
       list-folded="true"
       list-max-height="200px">
      </meting-js>
   </div>
</template>

<script setup>

</script>

<script>
export default {
  name: ''
}
</script>

<style>
.Meting{
  margin: 40px auto 0;
  max-width: 400px;
}

@media screen and (max-width: 996px){
  .Meting {
      margin-top: 0px;
      padding: 0 4%;
  }
}
</style>
<template>
  <div id="Header" class="shadow">
    <div class="left">
      <RouterLink class="Name" to="/">小晖晖</RouterLink>
    </div>
    <div class="right">
      <div class="input">
        <el-input
          v-model="text"
          class="w-50 m-2"
          placeholder="搜索点什么好呢.."
          :prefix-icon="Search"
          @change="textChange"
        />
      </div>
      <div class="box">
        <p v-for="(item, index) in NavList" :key="index">
          <el-button
            RouterHref
            :type="RouterHref === item.navBarUrl ? 'primary' : ''"
            link
            v-if="item.children === null"
            @click="$router.push({ path: `${item.navBarUrl}` })"
            ><i class="iconfont" v-html="item.navBarIcon"></i
            >{{ item.navBarName }}</el-button
          >
          <el-dropdown v-else>
            <span class="el-dropdown-link">
              <i class="iconfont" v-html="item.navBarIcon"></i
              >{{ item.navBarName
              }}<i class="iconfont" style="margin-left: 4px">&#xe602;</i>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item
                  v-for="(items, indens) in item.children"
                  :key="indens"
                  @click="dropdownClick(items)"
                >
                  <i
                    class="iconfont"
                    v-if="items.navBarIcon != ''"
                    v-html="items.navBarIcon"
                  ></i
                  >{{ items.navBarName }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </p>
      </div>
      <el-button link @click="dialog = !dialog">
        <el-icon>
          <Operation />
        </el-icon>
      </el-button>
    </div>
  </div>

  <el-drawer
    ref="drawerRef"
    v-model="dialog"
    direction="rtl"
    size="50%"
    class="demo-drawer"
    :show-close="false"
  >
  </el-drawer>
</template>

<script setup>
import { ref, onMounted, watch } from "vue";
import {
  Search,
  CaretBottom,
  Operation,
  Management,
} from "@element-plus/icons-vue";
import { getNavList } from "@/api/nav";
import { useRouter } from "vue-router";
const router = useRouter();
const dialog = ref(false);
const text = ref(""); // 输入框内容
const RouterHref = ref(); // 当前路由
const NavList = ref([]);
const dropdownClick = (item) => {
  console.log(item);
  window.open(item.navBarUrl);
};
// 搜索
const textChange = () => {
  if (text.value) {
    router.push({
      path: "/search",
      query: {
        title: text.value,
      },
    });
  } else {
    router.push({
      path: "/",
    });
  }
};
onMounted(() => {
  getNavList().then((res) => {
    console.log(res);
    if (res.code === 200) {
      res.data.forEach((ele) => {
        if (ele.children != null) {
          ele.children = JSON.parse(ele.children);
        }
        NavList.value.push(ele);
      });
      console.log(NavList.value);
    }
  });
});

// 监听当前路由
watch(
  () => router.currentRoute.value,
  (newValue) => {
    RouterHref.value = newValue.href;
  },
  { immediate: true }
);
</script>

<style lang="scss">
.shadow {
  background-color: #ffffff;
  box-shadow: 0px 5px 40px -1px rgba(2, 10, 18, 0.1);
}

#Header {
  height: 52px;
  border-bottom: 1px solid #f3f3f3;
  // background-color: #414753;
  // background-color: rgba($color: #414753, $alpha: 0.8);
  width: 100%;
  position: fixed;
  // margin-bottom: 20px;
  z-index: 2000;
  display: flex;
  padding: 0 20px;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;

  .left {
    display: flex;
    justify-content: center;

    .Name {
      font-size: 18px;
      color: #242424;
      font-weight: 600;
      text-decoration: none;
    }
  }

  .right {
    display: flex;
    height: 100%;
    align-items: center;
    .input {
      height: 100%;
      display: flex;
      align-items: center;
    }
    .box {
      display: flex;
      align-items: center;

      // .el-dropdown{
      //   span{height: 20px;}
      // }
      button,
      div {
        i {
          margin-right: 6px;
        }
        height: 32px;
        font-size: 16px;
        span {
          height: 32px;
          line-height: 32px;
          display: flex;
          align-items: center;
        }

        margin: 0 10px;
      }
    }
    & > button {
      font-size: 18px;
      color: #242424;
      display: none;
    }
  }
}
.el-overlay {
  margin-top: 52px;
}

@media screen and (max-width: 1200px) {
  #Header {
    width: 100%;
  }
}

@media screen and (max-width: 996px) {
  #Header {
    .right {
      & > button {
        display: block;
        margin-left: 8px;
      }
    }

    .right {
      .box {
        display: none;
      }
    }
  }
}
</style>

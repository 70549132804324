import { createRouter, createWebHashHistory } from "vue-router";
import Layout from "../layout/index.vue";
const routes = [
  {
    path: "/",
    component: Layout,
    children: [
      {
        path: "/",
        name: "Home",
        component: () => import("../views/Home.vue"),
        meta: { title: "首页" },
      },
      {
        path: "/details",
        name: "Details",
        component: () => import("../views/Details.vue"),
        meta: { title: "详情" },
      },
      {
        path: "/search",
        name: "Search",
        component: () => import("../views/Search.vue"),
        meta: { title: "搜索" },
      },
      {
        path: "/pigeonhole",
        name: "Pigeonhole",
        component: () => import("../views/Pigeonhole.vue"),
        meta: { title: "归档" },
      },
      {
        path: "/classify",
        name: "Classify",
        component: () => import("../views/Classify.vue"),
        meta: { title: "查询" },
      },
    ],
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: function () {
  //     return import(/* webpackChunkName: "about" */ '../views/About.vue')
  //   }
  // }
];

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),  //历史模式
  history: createWebHashHistory(process.env.BASE_URL), //Hash模式
  routes,
});

export default router;

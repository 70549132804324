/**
 * 权限拦截：导航守卫 + Token
 */

import router from "@/router";

// 导入进度条插件
// import nprogress from 'nprogress'
import nprogress from "nprogress";
import "nprogress/nprogress.css";

// 设置页面的标题
import getPageTitle from "./utils/get-page-title";

// 创建白名单，用户不需要有 Token 可以直接访问的页面
// const whiteList = ['/login', '/404', '/', '/cloudcollegelist', '/popularlist', '/uerdealmakingtradinginfo', '/uerdealmakingtradingdetails', '/uerdealmaking','/move-popularreported']

// 创建前置守卫
router.beforeEach((to, from, next) => {
  console.log(to.path);
  // 开启进度条
  nprogress.start();
  next();

  // 隐藏进度条
  nprogress.done();
});

// router.beforeEach((to, from, next) => {
//   // 设置页面的标题
//   document.title = getPageTitle(to.meta.title)
//   next()
// })

// 后置钩子，一般用于分析、更改页面标题、声明页面等辅助功能
router.afterEach((to, from) => {
  // 设置页面的标题
  document.title = getPageTitle(to.meta.title);
  // 隐藏进度条
  nprogress.done();
});

<template>
  <router-view />
  <canvas id="Snow"></canvas>
</template>
<script setup>
import { ref, onMounted } from "vue";

onMounted(() => {
  const html = document.documentElement;
  const htmlHeight = html.clientHeight;
  const DIVApp = document.getElementById("app");
  DIVApp.style.height = htmlHeight + "px";
  Canvas();
});

const Canvas = () => {
  var flakes = [],
    canvas = document.getElementById("Snow"),
    ctx = canvas.getContext("2d"),
    flakeCount = 50,
    mX = -100,
    mY = -100;

  canvas.width = window.innerWidth;
  canvas.height = window.innerHeight;

  function snow() {
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    for (var i = 0; i < flakeCount; i++) {
      var flake = flakes[i],
        x = mX,
        y = mY,
        minDist = 150,
        x2 = flake.x,
        y2 = flake.y;

      var dist = Math.sqrt((x2 - x) * (x2 - x) + (y2 - y) * (y2 - y)),
        dx = x2 - x,
        dy = y2 - y;

      if (dist < minDist) {
        var force = minDist / (dist * dist),
          xcomp = (x - x2) / dist,
          ycomp = (y - y2) / dist,
          deltaV = force / 2;

        flake.velX -= deltaV * xcomp;
        flake.velY -= deltaV * ycomp;
      } else {
        flake.velX *= 0.98;
        if (flake.velY <= flake.speed) {
          flake.velY = flake.speed;
        }
        flake.velX += Math.cos((flake.step += 0.05)) * flake.stepSize;
      }

      ctx.fillStyle = "rgba(255,255,255," + flake.opacity + ")";
      flake.y += flake.velY;
      flake.x += flake.velX;

      if (flake.y >= canvas.height || flake.y <= 0) {
        reset(flake);
      }

      if (flake.x >= canvas.width || flake.x <= 0) {
        reset(flake);
      }

      ctx.beginPath();
      ctx.arc(flake.x, flake.y, flake.size, 0, Math.PI * 2);
      ctx.fill();
    }
    requestAnimationFrame(snow);
  }

  function reset(flake) {
    flake.x = Math.floor(Math.random() * canvas.width);
    flake.y = 0;
    flake.size = Math.random() * 3 + 2;
    flake.speed = Math.random() * 1 + 0.5;
    flake.velY = flake.speed;
    flake.velX = 0;
    flake.opacity = Math.random() * 0.5 + 0.3;
  }

  function init() {
    for (var i = 0; i < flakeCount; i++) {
      var x = Math.floor(Math.random() * canvas.width),
        y = Math.floor(Math.random() * canvas.height),
        size = Math.random() * 3 + 2,
        speed = Math.random() * 1 + 0.5,
        opacity = Math.random() * 0.5 + 0.3;

      flakes.push({
        speed: speed,
        velY: speed,
        velX: 0,
        x: x,
        y: y,
        size: size,
        stepSize: (Math.random() / 30) * 1,
        step: 0,
        angle: 180,
        opacity: opacity,
      });
    }

    snow();
  }

  document.addEventListener("mousemove", function (e) {
    (mX = e.clientX), (mY = e.clientY);
  });
  window.addEventListener("resize", function () {
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;
  });
  init();
};
</script>
<style lang="scss">
@font-face {
  font-family: "zcool-gdh_Regular";
  src: url("./assets/font/zcool-gdh_Regular.ttf");
  font-weight: normal;
  font-style: normal;
}
#app {
  font-family: "zcool-gdh_Regular" !important;
}
.iconfont {
  font-family: "iconfont" !important;
  font-size: 14px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
}
/* 在线链接服务仅供平台体验和调试使用，平台不承诺服务的稳定性，企业客户需下载字体包自行发布使用并做好备份。 */
@font-face {
  font-family: "iconfont"; /* Project id 3488623 */
  src: url("//at.alicdn.com/t/c/font_3488623_5md0y26j8c5.woff2?t=1717134995170")
      format("woff2"),
    url("//at.alicdn.com/t/c/font_3488623_5md0y26j8c5.woff?t=1717134995170")
      format("woff"),
    url("//at.alicdn.com/t/c/font_3488623_5md0y26j8c5.ttf?t=1717134995170")
      format("truetype");
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
button,
textarea,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}

ol,
ul,
li {
  list-style: none;
}
#app {
  width: 100%;
  background-color: #eef2f3;
}
#Snow {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  // background: rgba(125,137,95,0.1);
  pointer-events: none;
}
</style>

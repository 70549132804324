import { createStore } from 'vuex'

export default createStore({
  state: {
    article: JSON.parse(localStorage.getItem('article')) || '',
    information: {
      articlesNum: "", // 文章数
      tagNum: "", // 标签数
      turnoverTime: "", // 最后更新时间
    }
  },
  mutations: {
    setArticleId(state, obj) {
      state.article = obj
    },
    setArticlesNum(state, num) {
      state.information.articlesNum = num;
    },
    setTagNumNum(state, num) {
      state.information.tagNum = num;
    },
    setTurnoverTime(state, time) {
      state.information.turnoverTime = time;
    }
  },
  actions: {
    sum_setArticleId (context, obj) {
      console.log(obj);
      context.commit('setArticleId', obj)  // 通过context去触发mutions中的sum
      localStorage.setItem('article', JSON.stringify(obj))
    },
    get_articlesNum (context, num) {
      context.commit('setArticlesNum', num)
    },
    get_tagNum (context, num) {
      context.commit('setTagNumNum', num)
    },
    get_turnoverTime (context, time) {
      context.commit('setTurnoverTime', time)
    }
  },
  modules: {
  }
})

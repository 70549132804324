import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
// 饿了么中文文件
import locale from 'element-plus/lib/locale/lang/zh-cn'
import 'element-plus/theme-chalk/dark/css-vars.css'
import plugins from './utils/mdEditor'


import VMdPreviewHtml from '@kangc/v-md-editor/lib/preview-html';
import '@kangc/v-md-editor/lib/style/preview-html.css';

// 引入使用主题的样式
import '@kangc/v-md-editor/lib/theme/style/vuepress.css';
import './permission'



const app = createApp(App)
app.use(store)
app.use(router)
app.use(ElementPlus,{locale})
app.use(plugins)
app.use(VMdPreviewHtml)
app.mount('#app')

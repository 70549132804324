<template>
  <div class="Layout dark">
    <div class="bgRgba">
      <div class="container">
        <Header />
        <div id="shouPing">
          <div class="text-P">
            <div>
              <p>小晖晖-博客</p>
              <p>去发光！而不是被照亮！</p>
            </div>
          </div>
        </div>
        <div class="conter">
          <div class="conter-left">
            <router-view />
          </div>
          <div class="conter-right">
            <botton-time />
          </div>
        </div>

        <Footer />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from "vue";
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
import bottonTime from "../components/Right/bottomTime.vue";

import { useRouter } from "vue-router";
const router = useRouter();

components: {
  Header, Footer;
}

onMounted(() => {
  const html = document.documentElement;
  const htmlHeight = html.clientHeight;
  const DIVshouPing = document.getElementById("shouPing");
  DIVshouPing.style.height = htmlHeight / 1.5 + "px";
});
</script>

<script>
export default {
  name: "Layout",
};
</script>

<style lang="scss">
.Layout {
  width: 100%;
  height: 100%;
}
.Layout {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: url("../assets/images/meinv.jpg") no-repeat;
  background-size: 100% 100%;
  .bgRgba {
    background-color: rgba(255, 255, 255, 0.5);
    width: 100%;
    height: 100%;
  }
  .container {
    width: 100%;
    margin: 0 auto;
    height: 100%;
    overflow-y: scroll;
    #shouPing {
      width: 100%;
      background: url(https://lphadmin.cn/img/bg.jpg) no-repeat;
      background-size: 100% 100%;
      .text-P {
        height: 100%;
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        p {
          text-align: center;
          font-size: 20px;
          line-height: 42px;
          color: #242424;
          opacity: 0.8;
          &:first-child {
            font-size: 32px;
            opacity: 1;
          }
        }
      }
    }

    .conter {
      display: flex;
      padding: 20px 10%;
      justify-content: space-between;
      .conter-left {
        width: 76%;
      }
      .conter-right {
        width: 22%;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
}

@media screen and (max-width: 996px) {
  .Layout {
    .container {
      .conter {
        padding: 20px 4%;
        .conter-left {
          width: 100%;
        }
        .conter-right {
          display: none;
        }
      }
    }
  }
}
</style>

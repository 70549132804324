// 导入 settings 配置项
import defaultSettings from '@/settings'

// 导入 settings 中配置的标题名称，如果没有使用默认的
const title = defaultSettings.title || 'Vue3 Ts'

// 导出 getPageTitle 方法
// pageTitle：当前方法接收的参数，就是页面的标题
export default function getPageTitle(pageTitle) {
  if (pageTitle) {
    return `${pageTitle} - ${title}`
  }
  return `${title}`
}

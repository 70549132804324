<template>
  <div class="consult">
    <div class="consule_top">
      <i class="iconfont" style="margin-right: 4px">&#xe614;</i> 网站资讯
    </div>
    <div class="consule_info">
      <p class="info_item">
        本站运行时间：<span>{{ daysPassed }} 天</span>
      </p>
      <p class="info_item">
        文章数：<span>{{ information.articlesNum }} 条</span>
      </p>
      <p class="info_item">
        标签数：<span>{{ information.tagNum }} 个</span>
      </p>
      <p class="info_item">
        最后更新时间：<span>{{ information.turnoverTime }}</span>
      </p>
    </div>
  </div>
</template>

<script setup name="HombottomTime">
import { ref } from "vue";
import { onMounted } from "vue";
import { useStore } from "vuex";
const store = useStore();

const information = ref({
  articlesNum: "", // 文章数
  tagNum: "", // 标签数
  turnoverTime: "", // 最后更新时间
});
const daysPassed = ref();

// 获取网站运行天数
const getTime = () => {
  const time = new Date("2022-12-09").getTime();
  const dete = new Date().getTime();
  const timeDiff = dete - time;
  daysPassed.value = Math.floor(timeDiff / (1000 * 3600 * 24));
};

const getInfo = () => {
  console.log("网站资讯", store.state.information);
  information.value = store.state.information;
};

onMounted(() => {
  getTime();
  setTimeout(() => {
    getInfo();
  }, 100);
});
</script>

<style scoped lang="scss">
.consult {
  position: sticky;
  top: 52px;
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 4px;
  .consule_info {
    margin-top: 16px;
    .info_item {
      display: flex;
      justify-content: space-between;
    }
    .info_item + .info_item {
      margin-top: 4px;
    }
  }
}
</style>

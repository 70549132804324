import VMdPreview from '@kangc/v-md-editor/lib/preview';
import '@kangc/v-md-editor/lib/style/codemirror-editor.css';
import createCopyCodePlugin from '@kangc/v-md-editor/lib/plugins/copy-code/index';
import '@kangc/v-md-editor/lib/plugins/copy-code/copy-code.css';

//使用的是vuepress主题
import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js'
import '@kangc/v-md-editor/lib/theme/style/vuepress.css'


import Prism from 'prismjs'


// highlightjs
// import hljs from 'highlight.js';

VMdPreview.use(vuepressTheme, {
  Prism,
});
VMdPreview.use(createCopyCodePlugin())

export default VMdPreview
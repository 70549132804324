import request from '@/utils/request'

/**
 * 获取标签列表
 * @returns 
 */
export function getNavList() {
  return request({
      url: '/nav/select',
      method: 'GET',
  })
}
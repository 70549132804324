export default {

  // 设置项目的标题
  title: '小情绪',

  /**
   * @description 是否固定 navbar 头部区域
   * @type {boolean} true | false
   * @description Whether fix the header
   */
  fixedHeader: true,

  /**
   * @description 是否展示侧边栏头部的 Logo
   * @type {boolean} true | false
   * @description Whether show the logo in sidebar
   */
  sidebarLogo: true
}
